.font-14{
    font-size: 14px !important;
}
.ana-1-3{
    width: 400px;
    height: auto
}

.textarea-outline{
    outline: 2px solid #000;
}