.sidebar-icon {
    font-size: 25px;
}
.sidebar-item {
    cursor: pointer;
    p{
        font-size: 16px;
    }
    &:hover {
        color: #28a745;
        text-decoration: none;
    }
}
.sidebar-item.active {
    color: #57b785;
}

.width-side {
    width: 23%;
}
.width-main {
    width: 100%;
}
.width-100 {
    width: 100%;
}

.bg-admin {
    background-color: #f4f7fc;
}

.admin-sidebar{
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;    
}