.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.sticky-right{
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
}

.high-mb{
    margin-bottom: 160px;
}

.blank-box{
    height: 340px;
    width: 600px;
    background-color: lightblue;
}