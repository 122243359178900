.admin-logo {
    max-height: 50px;
}
.top-navigation {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: rgba($color: #fff, $alpha: 0.3);
    // filter: blur(8px);
    // -webkit-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.topnav {
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 0 60px rgba(68, 68, 68, 0.23);

    a,
    button {
        float: left;
        display: block;
        color: #040826;
        text-align: center;
        padding: 22px 28px;
        text-decoration: none;
        font-size: 17px;

        &:hover {
            background-color: #040826;
            color: white;
        }
        &.active {
            background-color: #04aa6d;
            color: white;
        }

        .icon {
            display: none;
        }
    }
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }
    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}
