
form {
    padding: 0;
    margin: 0;
    display: inline;
}

img {
    vertical-align: inherit;
}

.myelin-login-register a,
.myelin-login-register a:focus {
    color: #0d6efd;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:hover,
a:active {
    color: #025bdf;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:focus,
a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
    outline: none;
}

p {
    line-height: 1.8;
}

iframe {
    border: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #252b33;
}

/* =================================== */
/*  2. Helpers Classes
  /* =================================== */
/* Box Shadow */
.myelin-login-register .shadow-md {
    -webkit-box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
    box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
}

/* Border Radius */
.myelin-login-register .rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.myelin-login-register .rounded-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.myelin-login-register .rounded-start-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.myelin-login-register .rounded-end-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

/* Text Size */
.myelin-login-register .text-0 {
    font-size: 11px !important;
    font-size: 0.6875rem !important;
}

.myelin-login-register .text-1 {
    font-size: 12px !important;
    font-size: 0.75rem !important;
}

.myelin-login-register .text-2 {
    font-size: 14px !important;
    font-size: 0.875rem !important;
}

.myelin-login-register .text-3 {
    font-size: 16px !important;
    font-size: 1rem !important;
}

.myelin-login-register .text-4 {
    font-size: 18px !important;
    font-size: 1.125rem !important;
}

.myelin-login-register .text-5 {
    font-size: 21px !important;
    font-size: 1.3125rem !important;
}

.myelin-login-register .text-6 {
    font-size: 24px !important;
    font-size: 1.50rem !important;
}

.myelin-login-register .text-7 {
    font-size: 28px !important;
    font-size: 1.75rem !important;
}

.myelin-login-register .text-8 {
    font-size: 32px !important;
    font-size: 2rem !important;
}

.myelin-login-register .text-9 {
    font-size: 36px !important;
    font-size: 2.25rem !important;
}

.myelin-login-register .text-10 {
    font-size: 40px !important;
    font-size: 2.50rem !important;
}

.myelin-login-register .text-11 {
    font-size: calc(1.4rem + 1.8vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-11 {
        font-size: 2.75rem !important;
    }
}

.myelin-login-register .text-12 {
    font-size: calc(1.425rem + 2.1vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-12 {
        font-size: 3rem !important;
    }
}

.myelin-login-register .text-13 {
    font-size: calc(1.45rem + 2.4vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-13 {
        font-size: 3.25rem !important;
    }
}

.myelin-login-register .text-14 {
    font-size: calc(1.475rem + 2.7vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-14 {
        font-size: 3.5rem !important;
    }
}

.myelin-login-register .text-15 {
    font-size: calc(1.5rem + 3vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-15 {
        font-size: 3.75rem !important;
    }
}

.myelin-login-register .text-16 {
    font-size: calc(1.525rem + 3.3vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-16 {
        font-size: 4rem !important;
    }
}

.myelin-login-register .text-17 {
    font-size: calc(1.575rem + 3.9vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-17 {
        font-size: 4.5rem !important;
    }
}

.myelin-login-register .text-18 {
    font-size: calc(1.625rem + 4.5vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-18 {
        font-size: 5rem !important;
    }
}

.myelin-login-register .text-19 {
    font-size: calc(1.65rem + 4.8vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-19 {
        font-size: 5.25rem !important;
    }
}

.myelin-login-register .text-20 {
    font-size: calc(1.7rem + 5.4vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-20 {
        font-size: 5.75rem !important;
    }
}

.myelin-login-register .text-21 {
    font-size: calc(1.775rem + 6.3vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-21 {
        font-size: 6.5rem !important;
    }
}

.myelin-login-register .text-22 {
    font-size: calc(1.825rem + 6.9vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-22 {
        font-size: 7rem !important;
    }
}

.myelin-login-register .text-23 {
    font-size: calc(1.9rem + 7.8vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-23 {
        font-size: 7.75rem !important;
    }
}

.myelin-login-register .text-24 {
    font-size: calc(1.95rem + 8.4vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-24 {
        font-size: 8.25rem !important;
    }
}

.myelin-login-register .text-25 {
    font-size: calc(2.025rem + 9.3vw) !important;
}

@media (min-width: 1200px) {
    .myelin-login-register .text-25 {
        font-size: 9rem !important;
    }
}

.myelin-login-register .text-11,
.myelin-login-register .text-12,
.myelin-login-register .text-13,
.myelin-login-register .text-14,
.myelin-login-register .text-15,
.myelin-login-register .text-16,
.myelin-login-register .text-17,
.myelin-login-register .text-18,
.myelin-login-register .text-19,
.myelin-login-register .text-20,
.myelin-login-register .text-21,
.myelin-login-register .text-22,
.myelin-login-register .text-23,
.myelin-login-register .text-24,
.myelin-login-register .text-25 {
    line-height: 1.3;
}

/* Font Weight */
.myelin-login-register .fw-100 {
    font-weight: 100 !important;
}

.myelin-login-register .fw-200 {
    font-weight: 200 !important;
}

.myelin-login-register .fw-300 {
    font-weight: 300 !important;
}

.myelin-login-register .fw-400 {
    font-weight: 400 !important;
}

.myelin-login-register .fw-500 {
    font-weight: 500 !important;
}

.myelin-login-register .fw-600 {
    font-weight: 600 !important;
}

.myelin-login-register .fw-700 {
    font-weight: 700 !important;
}

.myelin-login-register .fw-800 {
    font-weight: 800 !important;
}

.myelin-login-register .fw-900 {
    font-weight: 900 !important;
}

/* Opacity */
.myelin-login-register .opacity-0 {
    opacity: 0;
}

.myelin-login-register .opacity-1 {
    opacity: 0.1;
}

.myelin-login-register .opacity-2 {
    opacity: 0.2;
}

.myelin-login-register .opacity-3 {
    opacity: 0.3;
}

.myelin-login-register .opacity-4 {
    opacity: 0.4;
}

.myelin-login-register .opacity-5 {
    opacity: 0.5;
}

.myelin-login-register .opacity-6 {
    opacity: 0.6;
}

.myelin-login-register .opacity-7 {
    opacity: 0.7;
}

.myelin-login-register .opacity-8 {
    opacity: 0.8;
}

.myelin-login-register .opacity-9 {
    opacity: 0.9;
}

.myelin-login-register .opacity-10 {
    opacity: 1;
}

/* Background light */
.myelin-login-register .bg-light-1 {
    background-color: #e9ecef !important;
}

.myelin-login-register .bg-light-2 {
    background-color: #dee2e6 !important;
}

.myelin-login-register .bg-light-3 {
    background-color: #ced4da !important;
}

.myelin-login-register .bg-light-4 {
    background-color: #adb5bd !important;
}

/* Background Dark */
.myelin-login-register .bg-dark {
    background-color: #111418 !important;
}

.myelin-login-register .bg-dark-1 {
    background-color: #212529 !important;
}

.myelin-login-register .bg-dark-2 {
    background-color: #343a40 !important;
}

.myelin-login-register .bg-dark-3 {
    background-color: #495057 !important;
}

.myelin-login-register .bg-dark-4 {
    background-color: #6c757d !important;
}

.myelin-login-register hr {
    opacity: 0.2;
}

/* =================================== */
/*  3. Layouts
  /* =================================== */
#main-wrapper.myelin-login-register {
    background: #fff;
}

.myelin-login-register .section {
    position: relative;
    padding: 4.5rem 0;
    overflow: hidden;
}

@media (max-width: 575.98px) {
    .myelin-login-register .section {
        padding: 3.5rem 0;
    }
}

@media (max-width: 575.98px) {
    .myelin-login-register .modal .close {
        position: absolute;
        z-index: 1;
        right: 8px;
    }
}
