hr.menu-line{
  opacity: .5;
  border-top: 1px solid #fff;
}
.my-sidebar {
  margin: 0;
  padding: 0;
  width: 280px;
  background-color: #040826;
  position: fixed;
  height: 100%;
  overflow: auto;
  box-shadow: 0 8px 8px 5px rgba(19, 19, 19, 0.16), 0 2px 10px 0 rgba(58, 58, 58, 0.12);
  .menu-item {
    display: block;
    color: rgb(235, 235, 235);
    padding: 12px;
    text-decoration: none;
font-size: 16px;
    &.active {
      background-color: #ffffff72;
      color: white;
    }

    &:hover {
      background-color: #ffffff35;
    }

    .icon {
      display: inline-block;
      margin-right: 20px;
    }
    a.menu-link {
      color: #fff !important;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

div.content {
  margin-left: 260px;
  // padding: 1px 16px;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}


.bg-gradient-primary{
  background-color: #040826;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #040826), to(#224abe));
  background-image: linear-gradient(120deg, #040826 1%, #050b3c 100%);
}