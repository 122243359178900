
/* =================================== */
/*  5. Social Icons
  /* =================================== */
  .myelin-login-register .social-icons {
    margin: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}

.myelin-login-register .social-icons li {
    margin: 0px 6px 4px;
    padding: 0;
    overflow: visible;
}

.myelin-login-register .social-icons li a {
    display: block;
    height: 38px;
    line-height: 38px;
    width: 38px;
    font-size: 18px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.myelin-login-register .social-icons li i {
    line-height: inherit;
}

.myelin-login-register .social-icons.social-icons-lg li a {
    height: 45px;
    line-height: 45px;
    width: 45px;
}

.myelin-login-register .social-icons li:hover a {
    opacity: 0.8;
}

.myelin-login-register .social-icons.social-icons-rounded li a {
    border-radius: .25rem;
}

.myelin-login-register .social-icons.social-icons-circle li a {
    border-radius: 50%;
}

.myelin-login-register .social-icons li.social-icons-twitter a {
    background-color: #00ACEE;
}

.myelin-login-register .social-icons li.social-icons-facebook a {
    background-color: #1874eb;
}

.myelin-login-register .social-icons li.social-icons-linkedin a {
    background-color: #0E76A8;
}

.myelin-login-register .social-icons li.social-icons-google a {
    background-color: #DB4437;
}

.myelin-login-register .social-icons li.social-icons-instagram a {
    background-color: #3F729B;
}

.myelin-login-register .social-icons li.social-icons-vk a {
    background-color: #2B587A;
}

.myelin-login-register .social-icons li.social-icons-yahoo a {
    background-color: #720E9E;
}

.myelin-login-register .social-icons li.social-icons-apple a {
    background-color: #000;
}

.myelin-login-register .social-icons li.social-icons-windows a {
    background-color: #0090f4;
}

.myelin-login-register .social-icons li.social-icons-amazon a {
    background-color: #f79400;
}

.myelin-login-register .btn-facebook {
    background-color: #1874eb;
    color: #fff !important;
}

.myelin-login-register .btn-facebook:hover {
    background-color: #1266d2;
}

.myelin-login-register .btn-outline-facebook {
    border-color: #1874eb;
    color: #1874eb;
}

.myelin-login-register .btn-outline-facebook:hover {
    background-color: #1874eb;
    color: #fff;
}
.myelin-login-register .btn-outline-linkedin {
    border-color: #0072B1;
    color: #0072B1;
}

.myelin-login-register .btn-outline-linkedin:hover {
    background-color: #0072B1;
    color: #fff;
}

.myelin-login-register .btn-twitter {
    background-color: #00ACEE;
    color: #fff !important;
}

.myelin-login-register .btn-twitter:hover {
    background-color: #0096cf;
}

.myelin-login-register .btn-outline-twitter {
    border-color: #00ACEE;
    color: #00ACEE;
}

.myelin-login-register .btn-outline-twitter:hover {
    background-color: #00ACEE;
    color: #fff;
}

.myelin-login-register .btn-google {
    background-color: #DB4437;
    color: #fff !important;
}

.myelin-login-register .btn-google:hover {
    background-color: #ce3325;
}

.myelin-login-register .btn-outline-google {
    border-color: #DB4437;
    color: #DB4437;
}

.myelin-login-register .btn-outline-google:hover {
    background-color: #DB4437;
    color: #fff;
}

.myelin-login-register .btn-linkedin {
    background-color: #0E76A8;
    color: #fff !important;
}

.myelin-login-register .btn-linkedin:hover {
    background-color: #0c628c;
}

.myelin-login-register .btn-outline-linkedin {
    border-color: #0E76A8;
    color: #0E76A8;
}

.myelin-login-register .btn-outline-linkedin:hover {
    background-color: #0E76A8;
    color: #fff;
}

.myelin-login-register .btn-instagram {
    background-color: #3F729B;
    color: #fff !important;
}

.myelin-login-register .btn-instagram:hover {
    background-color: #366285;
}

.myelin-login-register .btn-outline-instagram {
    border-color: #3F729B;
    color: #3F729B;
}

.myelin-login-register .btn-outline-instagram:hover {
    background-color: #3F729B;
    color: #fff;
}

.myelin-login-register .btn-vk {
    background-color: #2B587A;
    color: #fff !important;
}

.myelin-login-register .btn-vk:hover {
    background-color: #234863;
}

.myelin-login-register .btn-outline-vk {
    border-color: #2B587A;
    color: #2B587A;
}

.myelin-login-register .btn-outline-vk:hover {
    background-color: #2B587A;
    color: #fff;
}

.myelin-login-register .btn-yahoo {
    background-color: #720E9E;
    color: #fff !important;
}

.myelin-login-register .btn-yahoo:hover {
    background-color: #5e0c82;
}

.myelin-login-register .btn-outline-yahoo {
    border-color: #720E9E;
    color: #720E9E;
}

.myelin-login-register .btn-outline-yahoo:hover {
    background-color: #720E9E;
    color: #fff;
}

.myelin-login-register .btn-apple {
    background-color: #000;
    color: #fff !important;
}

.myelin-login-register .btn-apple:hover {
    background-color: black;
}

.myelin-login-register .btn-outline-apple {
    border-color: #000;
    color: #000;
}

.myelin-login-register .btn-outline-apple:hover {
    background-color: #000;
    color: #fff;
}

.myelin-login-register .btn-windows {
    background-color: #0090f4;
    color: #fff !important;
}

.myelin-login-register .btn-windows:hover {
    background-color: #007ed5;
}

.myelin-login-register .btn-outline-windows {
    border-color: #0090f4;
    color: #0090f4;
}

.myelin-login-register .btn-outline-windows:hover {
    background-color: #0090f4;
    color: #fff;
}

.myelin-login-register .btn-amazon {
    background-color: #f79400;
    color: #fff !important;
}

.myelin-login-register .btn-amazon:hover {
    background-color: #d88200;
}

.myelin-login-register .btn-outline-amazon {
    border-color: #f79400;
    color: #f79400;
}

.myelin-login-register .btn-outline-amazon:hover {
    background-color: #f79400;
    color: #fff;
}

/* =================================== */
/*  6. Extras
  /* =================================== */
/* 6.1 Form */
.myelin-login-register .form-control {
    border-color: #dae1e3;
    font-size: 16px;
    color: #656565;
}

.myelin-login-register .form-control.bg-light {
    background-color: #f5f5f6 !important;
}

.myelin-login-register .form-control.border-light {
    border-color: #f5f5f6 !important;
}

.myelin-login-register .form-control:not(.form-control-sm) {
    padding: .810rem .96rem;
    height: inherit;
}

.myelin-login-register .form-control-sm {
    font-size: 14px;
}

.myelin-login-register .icon-group {
    position: relative;
}

.myelin-login-register .icon-group .form-control {
    padding-left: 44px;
}

.myelin-login-register .icon-group .icon-inside {
    position: absolute;
    width: 50px;
    height: 54px;
    left: 0;
    top: 0;
    pointer-events: none;
    font-size: 18px;
    font-size: 1.125rem;
    color: #c4c3c3;
    z-index: 3;
    display: flex;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.myelin-login-register .icon-group.icon-group-end .form-control {
    padding-right: 44px;
    padding-left: 0.96rem;
}

.myelin-login-register .icon-group.icon-group-end .icon-inside {
    left: auto;
    right: 0;
}

.myelin-login-register .form-control-sm+.icon-inside {
    font-size: 0.875rem !important;
    font-size: 14px;
    top: calc(50% - 13px);
}

.myelin-login-register select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
    height: auto;
    padding-top: .700rem;
    padding-bottom: .700rem;
}

.myelin-login-register .form-control:focus {
    -webkit-box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
    box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
    border-color: #80bdff !important;
}

.myelin-login-register .form-control:focus[readonly] {
    box-shadow: none;
}

.myelin-login-register .input-group-text {
    border-color: #dae1e3;
    background-color: #f1f5f6;
    color: #656565;
}

.myelin-login-register .form-control::-webkit-input-placeholder {
    color: #b1b4b6;
}

.myelin-login-register .form-control:-moz-placeholder {
    /* FF 4-18 */
    color: #b1b4b6;
}

.myelin-login-register .form-control::-moz-placeholder {
    /* FF 19+ */
    color: #b1b4b6;
}

.myelin-login-register .form-control:-ms-input-placeholder,
.myelin-login-register .form-control::-ms-input-placeholder {
    /* IE 10+ */
    color: #b1b4b6;
}

/* 6.2 Form Dark */
.myelin-login-register .form-dark .form-control {
    border-color: #232a31;
    background: #232a31;
    color: #fff;
}

.myelin-login-register .form-dark .form-control:focus {
    border-color: #80bdff !important;
}

.myelin-login-register .form-dark .form-control::-webkit-input-placeholder {
    color: #777b7f;
}

.myelin-login-register .form-dark .form-control:-moz-placeholder {
    /* FF 4-18 */
    color: #777b7f;
}

.myelin-login-register .form-dark .form-control::-moz-placeholder {
    /* FF 19+ */
    color: #777b7f;
}

.myelin-login-register .form-dark .form-control:-ms-input-placeholder,
.myelin-login-register .form-dark .form-control::-ms-input-placeholder {
    /* IE 10+ */
    color: #777b7f;
}

.myelin-login-register .form-dark .icon-group .icon-inside {
    color: #777b7f;
}

.myelin-login-register .form-dark .form-check-input:not(:checked) {
    background-color: #232a31;
    border-color: #232a31;
}

/* 6.3 Form Border (Input with only bottom border)  */
.myelin-login-register .form-border .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    padding-left: 0px !important;
    color: black;
}

.myelin-login-register .form-border .form-control::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
}

.myelin-login-register .form-border .form-control:-moz-placeholder {
    /* FF 4-18 */
    color: rgba(0, 0, 0, 0.4);
}

.myelin-login-register .form-border .form-control::-moz-placeholder {
    /* FF 19+ */
    color: rgba(0, 0, 0, 0.4);
}

.myelin-login-register .form-border .form-control:-ms-input-placeholder,
.myelin-login-register .form-border .form-control::-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.4);
}

.myelin-login-register .form-border .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom-color: rgba(0, 0, 0, 0.7);
}

.myelin-login-register .form-border .form-control:focus.border-dark {
    border-color: #0d6efd !important;
}

.myelin-login-register .form-border .form-control:not(output):-moz-ui-invalid:not(:focus),
.myelin-login-register .form-border .form-control:not(output):-moz-ui-invalid:-moz-focusring:not(:focus) {
    border-bottom-color: #e10203;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.myelin-login-register .form-border .form-control:not(output):-moz-ui-invalid:not(:focus).border-dark,
.myelin-login-register .form-border .form-control:not(output):-moz-ui-invalid:-moz-focusring:not(:focus).border-dark {
    border-color: #e10203 !important;
}

.myelin-login-register .form-border select option {
    color: #666;
}

.myelin-login-register .form-border .icon-group .form-control {
    padding-left: 30px !important;
}

.myelin-login-register .form-border .icon-group .icon-inside {
    color: rgba(0, 0, 0, 0.25);
    width: 30px;
    height: 52px;
    display: flex;
    -ms-flex-align: center !important;
    align-items: center !important;
    -ms-flex-pack: start !important;
    justify-content: start !important;
}

.myelin-login-register .form-border .icon-group.icon-group-end .form-control {
    padding-right: 30px !important;
    padding-left: 0 !important;
}

.myelin-login-register .form-border .icon-group.icon-group-end .icon-inside {
    left: auto;
    right: 0;
    -ms-flex-pack: end !important;
    justify-content: end !important;
}

/* 6.4 Form Border Light (Input with only bottom border)  */
.myelin-login-register .form-border-light .form-control {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
    color: #fafafa;
}

.myelin-login-register .form-border-light .form-control::-webkit-input-placeholder {
    color: rgba(250, 250, 250, 0.7);
}

.myelin-login-register .form-border-light .form-control:-moz-placeholder {
    /* FF 4-18 */
    color: rgba(250, 250, 250, 0.7);
}

.myelin-login-register .form-border-light .form-control::-moz-placeholder {
    /* FF 19+ */
    color: rgba(250, 250, 250, 0.7);
}

.myelin-login-register .form-border-light .form-control:-ms-input-placeholder,
.myelin-login-register .form-border-light .form-control::-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(250, 250, 250, 0.7);
}

.myelin-login-register .form-border-light .form-control:focus {
    border-bottom-color: rgba(250, 250, 250, 0.8);
}

.myelin-login-register .form-border-light .form-control:focus.border-light {
    border-color: #0d6efd !important;
}

.myelin-login-register .form-border-light .icon-group .icon-inside {
    color: #777b7f;
}

.myelin-login-register .form-border-light select option {
    color: #333;
}

/* 6.5 Vertical Multilple input group */
.myelin-login-register .vertical-input-group .input-group:first-child {
    padding-bottom: 0;
}

.myelin-login-register .vertical-input-group .input-group:first-child * {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.myelin-login-register .vertical-input-group .input-group:last-child {
    padding-top: 0;
}

.myelin-login-register .vertical-input-group .input-group:last-child * {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.myelin-login-register .vertical-input-group .input-group:not(:last-child):not(:first-child) {
    padding-top: 0;
    padding-bottom: 0;
}

.myelin-login-register .vertical-input-group .input-group:not(:last-child):not(:first-child) * {
    border-radius: 0;
}

.myelin-login-register .vertical-input-group .input-group:not(:first-child) * {
    border-top: 0;
}

/* 6.6 Other Bootstrap Specific */
.myelin-login-register .btn {
    padding: 0.8rem 2.6rem;
    font-weight: 500;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.myelin-login-register .btn-sm {
    padding: 0.5rem 1rem;
}

.myelin-login-register .btn:not(.btn-link) {
    -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.myelin-login-register .btn:not(.btn-link):hover {
    -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.myelin-login-register .input-group-append .btn,
.myelin-login-register .input-group-prepend .btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.myelin-login-register .input-group-append .btn:hover,
.myelin-login-register .input-group-prepend .btn:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (max-width: 575.98px) {
    .myelin-login-register .btn:not(.btn-sm) {
        padding: .810rem 2rem;
    }

    .myelin-login-register .input-group>.input-group-append>.btn,
    .myelin-login-register .input-group>.input-group-prepend>.btn {
        padding: 0 0.75rem;
    }
}

.myelin-login-register .bg-primary,
.myelin-login-register .badge-primary {
    background-color: #0d6efd !important;
}

.myelin-login-register .bg-secondary {
    background-color: #0c2f55 !important;
}

.myelin-login-register .text-primary,
.myelin-login-register .link-primary,
.myelin-login-register .btn-link {
    color: #0d6efd !important;
}

.myelin-login-register .btn-link:hover,
.myelin-login-register .link-primary:hover {
    color: #025bdf !important;
}

.myelin-login-register .text-secondary {
    color: #0c2f55 !important;
}

.myelin-login-register .text-light {
    color: #dee3e4 !important;
}

.myelin-login-register .text-body {
    color: #4c4d4d !important;
}

.myelin-login-register a.bg-primary:focus,
.myelin-login-register a.bg-primary:hover,
.myelin-login-register button.bg-primary:focus,
.myelin-login-register button.bg-primary:hover {
    background-color: #025bdf !important;
}

.myelin-login-register .border-primary {
    border-color: #0d6efd !important;
}

.myelin-login-register .border-secondary {
    border-color: #0c2f55 !important;
}

.myelin-login-register .btn-primary {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.myelin-login-register .btn-primary:hover {
    background-color: #025bdf;
    border-color: #025bdf;
}

.myelin-login-register .btn-primary:not(:disabled):not(.disabled).active,
.myelin-login-register .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #025bdf;
    border-color: #025bdf;
}

.myelin-login-register .btn-primary.focus,
.myelin-login-register .btn-primary:focus {
    background-color: #025bdf;
    border-color: #025bdf;
}

.myelin-login-register .btn-primary:not(:disabled):not(.disabled).active:focus,
.myelin-login-register .btn-primary:not(:disabled):not(.disabled):active:focus,
.myelin-login-register .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.myelin-login-register .btn-secondary {
    background-color: #0c2f55;
    border-color: #0c2f55;
}

.myelin-login-register .btn-outline-primary,
.myelin-login-register .btn-outline-primary:not(:disabled):not(.disabled).active,
.myelin-login-register .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #0d6efd;
    border-color: #0d6efd;
}

.myelin-login-register .btn-outline-primary:hover,
.myelin-login-register .btn-outline-primary:not(:disabled):not(.disabled).active:hover,
.myelin-login-register .btn-outline-primary:not(:disabled):not(.disabled):active:hover {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: #fff;
}

.myelin-login-register .btn-outline-secondary {
    color: #0c2f55;
    border-color: #0c2f55;
}

.myelin-login-register .btn-outline-secondary:hover {
    background-color: #0c2f55;
    border-color: #0c2f55;
    color: #fff;
}

.myelin-login-register .progress-bar,
.myelin-login-register .nav-pills .nav-link.active,
.myelin-login-register .nav-pills .show>.nav-link,
.myelin-login-register .dropdown-item.active,
.myelin-login-register .dropdown-item:active {
    background-color: #0d6efd;
}

.myelin-login-register .page-item.active .page-link {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.myelin-login-register .list-group-item.active {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.myelin-login-register .page-link {
    color: #0d6efd;
}

.myelin-login-register .page-link:hover {
    color: #025bdf;
}

/* styles-switcher */
#styles-switcher {
    background: #fff;
    width: 202px;
    position: fixed;
    top: 35%;
    z-index: 1051;
    padding: 20px;
    left: -202px;
}

#styles-switcher ul {
    padding: 0;
}

#styles-switcher ul li {
    list-style-type: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 4px 2px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    color: #fff;
    transition: all .2s ease-in-out;
}

#styles-switcher ul li.blue {
    background: #007bff;
}

#styles-switcher ul li.brown {
    background: #795548;
}

#styles-switcher ul li.purple {
    background: #6f42c1;
}

#styles-switcher ul li.indigo {
    background: #6610f2;
}

#styles-switcher ul li.red {
    background: #dc3545;
}

#styles-switcher ul li.orange {
    background: #fd7e14;
}

#styles-switcher ul li.yellow {
    background: #ffc107;
}

#styles-switcher ul li.green {
    background: #28a745;
}

#styles-switcher ul li.teal {
    background: #20c997;
}

#styles-switcher ul li.pink {
    background: #e83e8c;
}

#styles-switcher ul li.cyan {
    background: #17a2b8;
}

#styles-switcher ul li.active {
    transform: scale(0.7);
    cursor: default;
}

#styles-switcher .switcher-toggle {
    position: absolute;
    background: #555;
    color: #fff;
    font-size: 1.25rem;
    border-radius: 0px 4px 4px 0;
    right: -40px;
    top: 0;
    width: 40px;
    height: 40px;
    padding: 0;
}

#styles-switcher .switcher-toggle:focus {
    box-shadow: none;
}

#styles-switcher #reset-color {
    background: #007bff;
}

input:-internal-autofill-selected {
    background-color: transparent;
}

#styles-switcher.right {
    left: auto;
    right: -202px;
}

#styles-switcher.right .switcher-toggle {
    right: auto;
    left: -40px;
    border-radius: 4px 0px 0px 4px;
}