@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Poppins:wght@400;600&display=swap");

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    /* font-family: "Varela Round", sans-serif; */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    scroll-behavior: smooth !important;
}
body {
    background: #f1f3f6;
    color: #4c4d4d;
    font-family: "Poppins", sans-serif;
}

body::-webkit-scrollbar {
    width: 0.6em;
}

body::-webkit-scrollbar-track {
    border-left: 1px solid rgba(#040826, 0.984);
    -webkit-box-shadow: inset 0 0 6px #040826;
}

body::-webkit-scrollbar-thumb {
    background-color: #0408269a;
    border-radius: 30px;
}

.section-heading {
    font-weight: bold;
}
.heading-shadow {
    text-shadow: 1px 1px 3px #040826;
}
.section-heading span {
    background: #aa4465;
    padding: 0 4px;
}
.no-break{
    white-space: nowrap;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Varela Round", sans-serif;

    /* font-family: "Lato", sans-serif; */
}



.font-14{
    font-size: 14;
}