.myelin-login-register .shadow-md {
    -webkit-box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
    box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
}
.opacity-4 {
    opacity: 0.4;
}
hr.analysis{
    opacity: .6;
}

.sign-out{
    height: 600px;
}
.myelin-login-register hr {
    opacity: 0.2;
}


.myelin-login-register .section {
    position: relative;
    padding: 4.5rem 0;
    overflow: hidden;
}

@media (max-width: 575.98px) {
    .myelin-login-register .section {
        padding: 3.5rem 0;
    }
}

@media (max-width: 575.98px) {
    .myelin-login-register .modal .close {
        position: absolute;
        z-index: 1;
        right: 8px;
    }
}


.myelin-login-register .hero-wrap {
    position: relative;
    overflow: hidden;
}

.myelin-login-register .hero-wrap .hero-mask,
.myelin-login-register .hero-wrap .hero-bg, 
.myelin-login-register .hero-wrap .hero-bg-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.myelin-login-register .hero-wrap .hero-mask {
    z-index: 1;
}

.myelin-login-register .hero-wrap .hero-content {
    position: relative;
    z-index: 2;
}

.myelin-login-register .hero-wrap .hero-particles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.myelin-login-register .hero-wrap .hero-bg-slideshow {
    z-index: 0;
}

.myelin-login-register .hero-wrap .hero-bg {
    z-index: 0;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    transition: background-image 300ms ease-in 200ms;
}

.myelin-login-register .hero-wrap .hero-bg.hero-bg-scroll {
    background-attachment: scroll;
}

.myelin-login-register .hero-wrap .hero-bg-slideshow .hero-bg {
    background-attachment: inherit;
}

.myelin-login-register .hero-wrap .hero-bg-slideshow.owl-carousel .owl-stage-outer,
.myelin-login-register .hero-wrap .hero-bg-slideshow.owl-carousel .owl-stage,
.myelin-login-register .hero-wrap .hero-bg-slideshow.owl-carousel .owl-item {
    height: 100%;
}
