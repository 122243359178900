// $blue: #007bff !default;
// $indigo: #6610f2 !default;
// $purple: #6f42c1 !default;
// $pink: #e83e8c !default;
// $red: #dc3545 !default;
// $orange: #fd7e14 !default;
// $yellow: #ffc107 !default;
// $green: #28a745 !default;
// $teal: #20c997 !default;
// $cyan: #17a2b8 !default;

// $blue: red !default;
// $indigo: #6610f2 !default;
// $purple: #6f42c1 !default;
// $pink: #e83e8c !default;
// $red: #dc3545 !default;
// $orange: #fd7e14 !default;
// $yellow: #ffc107 !default;
// $green: #28a745 !default;
// $teal: #20c997 !default;
// $cyan: #17a2b8 !default;

$theme-colors: (
    "primary": #040826,
    "danger": #aa4465,
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./reset.scss";

@media (min-width: 768px) {
    .container {
        max-width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 970px;
    }
}
@media (min-width: 1300px) {
    .container {
        max-width: 1210px;
    }
}
@media (min-width: 1650px) {
    .container {
        max-width: 1400px;
    }
}

.bg-light {
    background-color: rgba(#5a6adf, 0.05) !important;
}

a.dropdown-item.active {
    a {
        color: #fff !important;
    }
}

.font-24 {
    font-size: 24px;
}

.hero {
    .carousel-indicators li.active {
        background-color: #ffffff;
    }

    .carousel-indicators li {
        background-color: rgba(255, 255, 255, 0.745) !important;
    }
}
