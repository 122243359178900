p {
    margin-bottom: 0 !important;
}
.navbar .btn {
    margin-top: 0;
}
.font-bold {
    font-weight: 600;
}
.font-20 {
    font-size: 20px;
}
.font-18 {
    font-size: 18px;
}
.font-16 {
    font-size: 16px;
}
p {
    font-size: 18px;
}
.text-underline {
    text-decoration: underline;
}
.custom-round {
    padding: 12px 32px;
    border-radius: 50px;
    transition: all 0.3s ease;
    border: none;
}
.custom-round:hover {
    transform: translateY(-2px);
}
.btn-danger {
    background: linear-gradient(107deg, #aa4465, #af516f);
    transition: all 0.3s ease;
    box-shadow: 0px 10px 20px rgba($color: #aa4465, $alpha: 0.4);
}
.btn-primary {
    background: linear-gradient(to right, #040826, #040826);
    transition: all 0.3s ease;
    box-shadow: 0px 10px 20px rgba($color: #040826, $alpha: 0.4);
}
.btn-primary:hover {
    background: linear-gradient(to left, #040826, #040826);
    box-shadow: 0px 10px 40px rgba($color: #040826, $alpha: 0);
}
.btn-danger:hover {
    background: linear-gradient(107deg, #aa4465, #aa4465);
    box-shadow: 0px 10px 40px rgba($color: #aa4465, $alpha: 0);
}

.iframe-video {
    width: 755px;
    height: 424px;
    max-width: 100%;
    max-height: calc((100vw - 40px) / (16 / 9));
}

section.bg-light {
    background-color: rgba(90, 92, 223, 0.1) !important;
}
